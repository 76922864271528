import { Space, Text } from '@mantine/core';
import { useEffect } from 'react';
import { FeaturesList } from '../components/FeaturesList';
import { Loading } from '../components/Loading';
import { useFeatureActions } from '../hooks/useFeatureActions';

export const YourFeatures = () => {
  const { getYourFeatures, state } = useFeatureActions();

  useEffect(() => {
    getYourFeatures();
  }, [getYourFeatures]);

  if (
    state.isLoading &&
    !state.yourFeatures.draft.length &&
    !state.yourFeatures.published.length
  ) {
    return <Loading />;
  }

  return (
    <div>
      <Text weight="bold">In draft</Text>
      <Space h="sm" />
      <FeaturesList
        isLoading={state.isLoading}
        features={state.yourFeatures.draft}
      />

      <Space h="xl" />
      <Text weight="bold">Published features</Text>
      <Space h="sm" />
      <FeaturesList
        isLoading={state.isLoading}
        features={state.yourFeatures.published}
      />
    </div>
  );
};
