import {
  ChatBubbleIcon,
  EnvelopeOpenIcon,
  PersonIcon,
  BarChartIcon,
  PaperPlaneIcon,
  PlusIcon,
  BadgeIcon,
  HamburgerMenuIcon,
  GearIcon,
} from '@modulz/radix-icons';
import {
  Menu,
  Divider,
  Button,
  Header,
  Image,
  ActionIcon,
  ButtonProps,
  Modal,
  Text,
} from '@mantine/core';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import logo from '../assets/images/logo.png';
import { Link } from './Link';
import { routePaths } from '../AppRoutes';
import { useAuth } from '../hooks/useAuth';
import React, { useState } from 'react';
import { SlackIcon } from './Icons';

const NavButton = styled(
  ({ isActive, ...props }: { isActive: boolean } & ButtonProps<'button'>) => (
    <Button {...props} />
  ),
)`
  font-weight: 500;
  ${({ isActive }) =>
    !isActive
      ? `
        background-color: rgb(248, 249, 250);
        color: rgb(73, 80, 87);`
      : ''}
`;

const FeedbackModal: React.FC<{
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ opened, setOpened }) => {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="We love to hear from you!"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Text>You can reach us on our</Text>

        <Button
          style={{ marginRight: 8 }}
          variant="link"
          target="_blank"
          component="a"
          href="https://messagebird.slack.com/archives/C014V3DJSTF"
        >
          <SlackIcon height={20} width={20} />
          Slack channel
        </Button>
        <Text>or via email</Text>
        <Button
          component="a"
          target="_blank"
          href="mailto:ocw-team@messagebird.com"
          variant="link"
        >
          ocw-team@messagebird.com
        </Button>
      </div>
    </Modal>
  );
};

const Settings = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const { isAdmin } = useAuth();

  return (
    <>
      <FeedbackModal
        opened={isFeedbackModalOpen}
        setOpened={setIsFeedbackModalOpen}
      />
      <Menu
        placement="start"
        zIndex={10000}
        control={
          <ActionIcon>
            <HamburgerMenuIcon />
          </ActionIcon>
        }
      >
        <Menu.Label>General</Menu.Label>
        <Menu.Item
          component={Link}
          to={routePaths.contributors}
          icon={<BarChartIcon />}
        >
          Contributors
        </Menu.Item>
        <Menu.Item
          component={Link}
          to={routePaths.yourFeatures}
          icon={<ChatBubbleIcon />}
        >
          Your features
        </Menu.Item>
        <Menu.Item
          onClick={() => setIsFeedbackModalOpen(true)}
          icon={<EnvelopeOpenIcon />}
        >
          Give us feedback
        </Menu.Item>
        <Divider />
        <Menu.Label>Profile</Menu.Label>
        <Menu.Item color="red" icon={<PersonIcon />}>
          Logout
        </Menu.Item>
      </Menu>

      {isAdmin ? (
        <Menu
          placement="start"
          zIndex={10000}
          control={
            <ActionIcon>
              <GearIcon />
            </ActionIcon>
          }
        >
          <Menu.Item
            component={Link}
            to={routePaths.adminManageFeatures}
            icon={<PaperPlaneIcon />}
          >
            Manage features
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={routePaths.adminTags}
            icon={<BadgeIcon />}
          >
            Manage tags
          </Menu.Item>
        </Menu>
      ) : null}
    </>
  );
};

export const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <Header fixed padding={8} height={'auto'}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              to={routePaths.index}
              style={{
                display: 'flex',
                margin: '0 4px',
              }}
            >
              <Image
                radius="md"
                fit="contain"
                style={{
                  height: '40px',
                  width: '40px',
                  display: 'inline-block',
                }}
                src={logo}
              />
            </Link>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link
            to={routePaths.inProgress}
            style={{
              margin: '0 4px',
            }}
          >
            <NavButton
              radius="xl"
              isActive={pathname === routePaths.inProgress}
            >
              In progress
            </NavButton>
          </Link>

          <Link
            style={{
              margin: '0 4px',
            }}
            to={routePaths.submit}
          >
            <NavButton isActive={pathname === routePaths.submit} radius="xl">
              <PlusIcon />
              <span>Submit a feature</span>
            </NavButton>
          </Link>

          <Settings />
        </div>
      </div>
    </Header>
  );
};
