import { Center, Text } from '@mantine/core';
import { ExclamationTriangleIcon } from '@modulz/radix-icons';
import { useOoos } from '../hooks/useOoos';

export const NotFound = () => {
  const {ooos, onOoos}= useOoos()

  return (
    <Center style={{ width: 600, height: 600 }}>
      <ExclamationTriangleIcon
        onClick={onOoos}
        style={{ userSelect: 'none', cursor: 'pointer' }}
      />
      <Text
        onClick={onOoos}
        style={{ paddingLeft: 4, userSelect: 'none', cursor: 'pointer' }}
      >
        O{ooos}ops!
      </Text>
    </Center>
  );
};
