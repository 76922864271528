import { getApp } from 'firebase/app';
import {
  initializeFirestore,
  query,
  getDocs,
  getDoc,
  collection,
  orderBy,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
  runTransaction,
  where,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';

export type FeatureStatus =
  | 'draft'
  | 'voting'
  | 'voting_closed'
  | 'in_queue'
  | 'in_progress'
  | 'done';

export type Feature = {
  id: string;
  title: string;
  description: string;
  created_by: string;
  created_at: Date;
  importance: number;
  highImportanceReason?: string;
  votes: string[];
  tags: string[];
  status: FeatureStatus;
  customer_size: 'smb' | 'enterprise';
  is_deleted: boolean;
  customer_type: 'prospect' | 'existing';
};

export type FeaturePayload = Omit<
  Feature,
  'is_deleted' | 'created_at' | 'id' | 'votes' | 'tags'
>;

export const fetchFeatures = async () => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const features: Feature[] = [];
    const q = query(
      collection(database, `features`),
      orderBy('status'),
      where('status', '!=', 'draft'),
      orderBy('created_at'),
    );
    const docs = await getDocs(q);

    docs.forEach((feature) => {
      const featureData = feature.data();
      features.push({
        ...featureData,
        id: feature.id,
        created_at: featureData.created_at?.toDate(),
      } as Feature);
    });

    return features;
  } catch (e) {
    console.error(e);
  }
};

export const fetchYourFeatures = async (uid: string) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const features: Feature[] = [];
    const q = query(
      collection(database, `features`),
      // orderBy('created_at'),
      where('created_by', '==', uid),
    );
    const docs = await getDocs(q);

    docs.forEach((feature) => {
      const featureData = feature.data();
      features.push({
        ...featureData,
        id: feature.id,
        created_at: featureData.created_at?.toDate(),
      } as Feature);
    });

    return features;
  } catch (e) {
    console.error(e);
  }
};

export const fetchFeatureById = async (id: string) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });

    const docSnap = await getDoc(doc(database, 'features', id));
    if (!docSnap.exists()) return null;

    return {
      ...(docSnap.data() as Feature),
      created_at: docSnap.data().created_at.toDate(),
      id: docSnap.id,
    };
  } catch (e) {
    console.error(e);
  }
};

export const createFeature = async (feature: FeaturePayload) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const addRef = collection(database, `features`);
    await addDoc(addRef, {
      ...feature,
      created_at: serverTimestamp(),
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateFeature = async (id: string, feature: FeaturePayload) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    return await updateDoc(doc(database, `features`, id), {
      ...feature,
      updated_at: serverTimestamp(),
    });
  } catch (e) {
    console.error(e);
  }
};

export const deleteFeature = async (id: string) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    await deleteDoc(doc(database, 'features', id));
  } catch (e) {
    console.error(e);
  }
};

export const updateFeatureVotes = async (id: string, uid: string) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const docRef = doc(collection(database, `features`), `${id}`);

    await runTransaction(database, (transaction) => {
      return transaction.get(docRef).then((doc) => {
        const votes = doc.get('votes');

        transaction.update(docRef, {
          votes: votes?.includes(uid) ? arrayRemove(uid) : arrayUnion(uid),
        });
      });
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateFeatureStatus = async (
  id: string,
  status: FeatureStatus,
) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });

    return await updateDoc(doc(database, `features`, id), {
      status,
      updated_at: serverTimestamp(),
    });
  } catch (e) {
    console.error(e);
  }
};
