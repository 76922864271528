import { useCallback, useState } from 'react';
import * as tagDocument from '../firebase/tags';
import type { Tag, TagPayload } from '../firebase/tags';

export const useTagsActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const getAllTags = useCallback(async () => {
    setIsLoading(true);
    const response = await tagDocument.fetchTags();
    if (response) {
      setTags(response);
    }
    setIsLoading(false);
  }, []);

  const createTag = useCallback(
    async (tag: TagPayload) => {
      setIsLoading(true);
      await tagDocument.createTag(tag);
      await getAllTags();
      setIsLoading(false);
    },
    [getAllTags],
  );

  return {
    isLoading,
    createTag,
    getAllTags,
    tags,
  };
};
