import {
  Badge,
  Button,
  Center,
  ColorInput,
  Group,
  Modal,
  Space,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { useTagsActions } from '../../hooks/useTagsActions';

type Tag = {
  name: string;
  color: string;
};

export const CreateModal: React.FC<{
  onSubmit: (tag: Tag) => Promise<void>;
  onClose: () => void;
  opened: boolean;
  isLoading: boolean;
}> = ({ onClose, opened, onSubmit, isLoading }) => {
  const form = useForm({
    initialValues: {
      name: '',
      color: '#ffffff',
    },
    validationRules: {
      name: (value) => !!value && value.length <= 15,
      color: (value) => !!value && value !== '#ffffff',
    },
  });

  return (
    <Modal title="Create a new tag" opened={opened} onClose={onClose}>
      <form onSubmit={form.onSubmit((values) => console.log(values))}>
        <TextInput
          value={form.values.name}
          label="Tag name"
          maxLength={15}
          placeholder="Buttons support"
          description="Feature title"
          error={form.errors.name && 'Please specify a valid tag name'}
          onChange={(event) => form.setFieldValue('name', event.target.value)}
        />
        <Space h="xl" />
        <ColorInput
          dropdownZIndex={100000}
          value={form.values.color}
          placeholder="Pick color"
          description="Tag background color"
          error={form.errors.color && 'Please pick a color that is not white!'}
          onChange={(value) => form.setFieldValue('color', value)}
          label="Tag color"
          swatches={[
            '#868e96',
            '#fa5252',
            '#e64980',
            '#be4bdb',
            '#7950f2',
            '#4c6ef5',
            '#228be6',
            '#15aabf',
            '#12b886',
            '#40c057',
            '#82c91e',
            '#fab005',
            '#fd7e14',
          ]}
        />
        <Space h="xl" />
        <Group position="right">
          <Button
            onClick={() => onSubmit(form.values)}
            loading={isLoading}
            radius="xl"
            type="submit"
          >
            Submit
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

const ExistingTags: React.FC<{ tags: Tag[]; isLoading: boolean }> = ({
  tags,
  isLoading,
}) => {
  if (isLoading && !tags.length) {
    return <Loading />;
  }
  
  if (!tags.length) {
    return <Center>Now tags created yet!</Center>;
  }

  return (
    <div style={{ display: 'flex' }}>
      {tags.map((tag) => (
        <Badge
          key={tag.name}
          style={{ background: tag.color, marginRight: 4 }}
          variant="filled"
        >
          {tag.name}
        </Badge>
      ))}
    </div>
  );
};

export const Tags = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { isLoading, tags, getAllTags, createTag } = useTagsActions();
  const onSubmitTagClick = async (tag: Tag) => {
    await createTag(tag);
    setIsCreateModalOpen(false);
  };

  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  return (
    <>
      <CreateModal
        isLoading={isLoading}
        onSubmit={onSubmitTagClick}
        opened={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
      />
      <Group position="right">
        <Button onClick={() => setIsCreateModalOpen(true)}>New label</Button>
      </Group>
      <Space h="xl" />
      <ExistingTags isLoading={isLoading} tags={tags} />
    </>
  );
};
