import { useNotifications } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';

export const useOoos = () => {
  const notification = useNotifications();
  const hasSeenNotificationRef = useRef(false);
  const [ooos, setOooos] = useState(['oo']);

  const onOoos = () => {
    setOooos((prev) => {
      if (prev.length === 100) {
        return prev;
      }
      return prev.concat('o');
    });
  };

  useEffect(() => {
    if (
      ooos.length === 10 &&
      notification.notifications.length === 0 &&
      !hasSeenNotificationRef.current
    ) {
      notification.showNotification({
        color: 'dark',
        title: 'Good job 🤓',
        message: "Don't stop please",
      });
      hasSeenNotificationRef.current = true;
    }
  }, [ooos, notification]);

  return {
    onOoos,
    ooos: ooos.join(''),
  };
};
