
const toCamel = (str: string):string => {
  return str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isObject = function (obj: Object):boolean {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToCamel = function (obj: Record<string, any>): Object {
  if (isObject(obj)) {
    const n: Record<string, any> = {};

    Object.keys(obj)
      .forEach((k: string) => {
        n[toCamel(k)] = keysToCamel(obj[k]);
      });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i);
    });
  }
  
  return obj;
};