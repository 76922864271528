import { useCallback, useState } from 'react';
import { getUsersInfo } from '../firebase/users';

export const useUserActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toggleIsLoading = useCallback(() => {
    setIsLoading((prev) => !prev);
  }, []);

  const getUsers = useCallback(
    async (ids: string[]) => {
      toggleIsLoading();
      const response = await getUsersInfo(ids);
      toggleIsLoading();

      return response
    },
    [toggleIsLoading],
  );

  return {
    isLoading,
    getUsers,
  };
};
