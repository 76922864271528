import { Container, MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { NotificationsProvider } from '@mantine/notifications';

import './App.css';
import { AuthProvider } from './hooks/useAuth';
import { FeatureActionsProvider } from './hooks/useFeatureActions';

const App = () => {
  return (
    <BrowserRouter>
      <MantineProvider
        theme={{
          fontFamily: 'IBM Plex Sans, sans-serif',
          spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 },
        }}
      >
        <NotificationsProvider position="top-right">
          <AuthProvider>
            <FeatureActionsProvider>
            <Container
              size="sm"
              padding="xl"
              style={{ marginTop: 32 + 68, marginBottom: 60 }}
            >
              <AppRoutes />
            </Container>
            </FeatureActionsProvider>
          </AuthProvider>
        </NotificationsProvider>
      </MantineProvider>
    </BrowserRouter>
  );
};

export default App;
