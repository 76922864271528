import { Center, Text, Avatar, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useUserActions } from '../../../../hooks/useUserActions';
import { User } from '../../../../firebase/users';

export const Voters: React.FC<{ votes: string[] }> = ({ votes }) => {
  const { getUsers, isLoading } = useUserActions();
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    const fetch = async () => {
      const response = await getUsers(votes);
      if (response) {
        setUsers(response);
      }
    };

    fetch();
  }, [getUsers, votes]);

  if (isLoading) {
    return (
      <Center style={{ height: '80px' }}>
        <Loader variant="dots" />
      </Center>
    );
  }

  return (
    <>
      {users.map((user) => (
        <React.Fragment key={user.id}>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}
          >
            <Avatar size="sm" radius="xl" src={user.avatar} alt="it's me" />
            <Text style={{ paddingLeft: 4 }}>{user.email}</Text>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
