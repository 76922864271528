import { Text, Group, Title, Space, Highlight } from '@mantine/core';
import { ScrollToTop } from '../components/scrollToTop';
import { useEffect } from 'react';
import { useFeatureActions } from '../hooks/useFeatureActions';
import { FeaturesList } from '../components/FeaturesList';

export const Home = () => {
  const { state, getFeatures } = useFeatureActions();

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  return (
    <>
      <Group spacing="xl">
        <Title>Vote and Prioritize</Title>
        <Text>
          <Highlight highlightColor="blue" highlight="Omnichannel Widget">
            This mini-application allows you to browse and vote on new features
            for Omnichannel Widget.
          </Highlight>
          You will notice that you have a certain amount of points/influence
          that can be used to vote for or against any of the features listed
          below.
        </Text>
      </Group>
      <Space h="xl" />
      <FeaturesList features={[...state.features]} isLoading={state.isLoading} />
      <ScrollToTop />
    </>
  );
};
