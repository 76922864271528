import { Card, Text } from '@mantine/core';
import { useEffect } from 'react';
import type { Feature } from '../../firebase/features';
import { useTagsActions } from '../../hooks/useTagsActions';
import { Description } from './Description';
import { DraftActions } from './DraftActions';
import { FeatureBadge } from '../FeatureBadge';
import { VoteButton } from './VoteButton';

type FeatureCardProps = {
  feature: Feature;
};

const formatDate = (date: string | Date) => new Date(date).toDateString();

export const FeatureCard: React.FC<FeatureCardProps> = ({ feature }) => {
  const isDraft = feature.status === 'draft';
  const { getAllTags } = useTagsActions();
  
  useEffect(() => {
    getAllTags();
  });
  
  return (
    <div style={{ width: '100%', margin: 'auto' }}>
      <Card padding="xs" withBorder>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text weight={500}>{feature.title || 'Draft feature'}</Text>
              <FeatureBadge status={feature.status} />
            </div>
            <Text weight={500} size="xs" color="gray">
              {formatDate(feature.created_at)}
            </Text>
          </div>

          {isDraft && <DraftActions feature={feature} />}

          {!isDraft && <VoteButton feature={feature} />}
        </div>
        {!isDraft && (
          <Description
            tags={feature.tags || []}
            description={feature.description}
          />
        )}
      </Card>
    </div>
  );
};
