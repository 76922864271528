import {
  Text,
  TextInput,
  Button,
  Space,
  InputWrapper,
  RadioGroup,
  Radio,
  Textarea,
  Slider,
  Group,
  Divider,
  Chips,
  Chip,
} from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { routePaths } from '../AppRoutes';
import { FeaturePayload } from '../firebase/features';
import { useFeatureActions } from '../hooks/useFeatureActions';
import { isValidUrl } from '../utils/isValidUrl';

type FormValues = {
  description: string;
  customerSize: string;
  customerType: string;
  customerName: string;
  customerWebsite: string;
  importance: number;
  highImportanceReason: string;
  title: string;
};

export const FeatureForm: React.FC<{
  initialValues?: Partial<FormValues>;
  id?: string;
}> = ({ id, initialValues }) => {
  const [isLoading, setIsLoading] = useState<'drafting' | 'submitting' | null>(
    null,
  );
  const { submitFeature } = useFeatureActions();
  const [showMoreQuestions, setShowMoreQuestions] = useState(false);
  const notification = useNotifications();
  const navigate = useNavigate()

  const form = useForm<FormValues>({
    initialValues: {
      description: '',
      customerSize: '',
      customerType: '',
      customerName: '',
      customerWebsite: '',
      importance: 0,
      highImportanceReason: '',
      title: '',
      ...initialValues,
    },

    validationRules: {
      title: (value) => !!value,
      customerSize: (value) => !!value,
      description: (value) => !!value,
      customerType: (value) => !!value,
      customerName: (value) => (value.length ? value.length > 2 : true),
      customerWebsite: (value) => (value.length ? isValidUrl(value) : true),
    },
  });

  const onSubmitClick = async (values: FormValues) => {
    setIsLoading('submitting');
    await submitFeature(
      snakeCaseKeys({
        ...values,
        status: 'voting',
      }) as Omit<FeaturePayload, 'created_by'>,
      id,
    );
    setIsLoading(null);
    navigate(routePaths.index);
  };
  const onDraftClick = async () => {
    setIsLoading('drafting');
    await submitFeature(
      snakeCaseKeys({
        ...form.values,
        status: 'draft',
      }) as Omit<FeaturePayload, 'created_by'>,
      id,
    );

    setIsLoading(null);
    notification.showNotification({
      color: 'green',
      title: 'Nice!',
      message: 'Draft saved successfully',
    });
  };

  return (
    <>
      <form onSubmit={form.onSubmit((values) => onSubmitClick(values))}>
        <TextInput
          required
          label="Title"
          description="Feature title"
          error={form.errors.title && 'Please specify valid title'}
          value={form.values.title}
          onChange={(event) =>
            form.setFieldValue('title', event.currentTarget.value)
          }
        />
        <Space h="md" />
        <Textarea
          required
          label="Description"
          description="Feature description"
          error={form.errors.description}
          value={form.values.description}
          onChange={(event) =>
            form.setFieldValue('description', event.target.value)
          }
        />
        <Space h="xl" />

        <RadioGroup
          label="Customer type"
          name="customerType"
          description="Is a new customer or an existing one?"
          error={form.errors.customerType && 'This is required'}
          value={form.values.customerType}
          size="sm"
          spacing="xs"
          required
          onChange={(value) => form.setFieldValue('customerType', value)}
        >
          <Radio value="existing">Existing customers</Radio>
          <Radio value="prospect">Prospect</Radio>
        </RadioGroup>

        <Space h="xl" />

        <RadioGroup
          name="customerSize"
          label="Customer size"
          description="The size of customer?"
          error={form.errors.customerSize && 'This is required'}
          value={form.values.customerSize}
          onChange={(value) => form.setFieldValue('customerSize', value)}
          required
          size="sm"
          spacing="xs"
        >
          <Radio value="enterprise">Enterprise customer</Radio>
          <Radio value="smb">SMB Customer</Radio>
        </RadioGroup>

        <Space h="xl" />

        <Textarea
          placeholder="Your comment"
          label="Extra notes (optional)"
          description="something else to add?"
        />

        <Space h="xl" />

        <InputWrapper
          label="Importance"
          description={
            <>
              <div>
                If you would rate this feature out of 10, how much would you
                give it?
              </div>
              <div>
                Just remember if everything is important, nothing is important!
              </div>
            </>
          }
        >
          <Slider
            name="importance"
            min={0}
            max={10}
            marks={[
              { value: 2, label: '2' },
              { value: 5, label: '5' },
              { value: 8, label: '8' },
            ]}
            value={form.values.importance}
            onChange={(value) => form.setFieldValue('importance', value)}
          />
        </InputWrapper>

        {form.values.importance > 7 && (
          <>
            <Space h="lg" />
            <Textarea
              placeholder="why do you think that?"
              label="Why do you think that?"
              description="Looks like you gave a high importance, would you mind explaining more?"
              value={form.values.highImportanceReason}
              onChange={(event) =>
                form.setFieldValue('highImportanceReason', event.target.value)
              }
            />
          </>
        )}
        <Space h="lg" />
        <Space h="lg" />

        <Divider />
        <Space h="xl" />

        <TextInput
          label="Customer name (optional)"
          description="The name of the company"
          error={form.errors.customerName && 'Please enter a valid name'}
          value={form.values.customerName}
          onChange={(event) =>
            form.setFieldValue('customerName', event.target.value)
          }
        />

        <Space h="xl" />

        <TextInput
          label="Customer website (optional)"
          description="customer webpage url. eg. https://messagebird.com"
          error={form.errors.customerWebsite && 'Please enter a valid url'}
          value={form.values.customerWebsite}
          onChange={(event) =>
            form.setFieldValue('customerWebsite', event.target.value)
          }
        />
        <Space h="xl" />

        <div style={{ display: 'flex' }}>
          <Text>
            Do you have couple more minutes to answer couple of Yes/No
            questions?
            <Button
              style={{ marginLeft: 4 }}
              variant="link"
              onClick={() => setShowMoreQuestions(true)}
            >
              Yeah sure!
            </Button>
          </Text>
        </div>
        {showMoreQuestions && (
          <>
            <Space h="xl" />
            <InputWrapper label="Is the customer migrating from a different solution?">
              <Chips variant="filled" style={{ marginTop: 8 }}>
                <Chip value="yes">YES</Chip>
                <Chip value="no">No</Chip>
              </Chips>
            </InputWrapper>
          </>
        )}

        <Space h="xl" />
        <Space h="xl" />
        <Group position="right">
          <Button
            onClick={onDraftClick}
            loading={isLoading === 'drafting'}
            variant="outline"
            radius="xl"
            disabled={!!isLoading}
          >
            Save as a draft
          </Button>

          <Button
            loading={isLoading === 'submitting'}
            radius="xl"
            type="submit"
            disabled={!!isLoading}
          >
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
};
