import React from 'react';
import { Badge, Group, Space, Spoiler, Text } from '@mantine/core';

export const Description: React.FC<{
  description: string;
  tags: string[];
}> = ({ description, tags }) => {
  return (
    <>
      <Space h="xs" />
      <div style={{ paddingRight: '100px' }}>
        <Spoiler maxHeight={50} showLabel="Show more" hideLabel="Hide">
          <Text dangerouslySetInnerHTML={{ __html: description }} />
        </Spoiler>
      </div>

      <Group position="left" style={{ marginTop: 5 }}>
        {tags?.map((tag) => (
          <Badge key={tag} color="pink" variant="filled">
            {tag}
          </Badge>
        ))}
      </Group>
    </>
  );
};
