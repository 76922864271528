import React, { useCallback, useContext, useReducer } from 'react';
import * as featureDocument from '../firebase/features';
import type {
  FeatureStatus,
  Feature,
  FeaturePayload,
} from '../firebase/features';
import { useAuth } from './useAuth';

type ActionType =
  | 'DELETE_PENDING'
  | 'DELETE_SUCCESS'
  | 'FETCH_BY_ID_PENDING'
  | 'FETCH_BY_ID_SUCCESS'
  | 'FETCH_ALL_PENDING'
  | 'FETCH_ALL_SUCCESS'
  | 'FETCH_YOUR_FEATURES_PENDING'
  | 'FETCH_YOUR_FEATURES_SUCCESS'
  | 'SUBMIT_PENDING'
  | 'SUBMIT_SUCCESS'
  | 'TOGGLE_FEATURE_VOTE'
  | 'UPDATE_FEATURE_STATUS_PENDING'
  | 'UPDATE_FEATURE_STATUS_SUCCESS';

type FeatureActionsAction = {
  type: ActionType;
  payload?: Feature[];
};

type FeatureActionsState = {
  isLoading: boolean;
  features: Feature[];
  yourFeatures: { draft: Feature[]; published: Feature[] };
  viewedFeature?: Feature;
};

const featuresInitialState: FeatureActionsState = {
  isLoading: false,
  features: [],
  yourFeatures: { draft: [], published: [] },
};

const featuresReducer = (
  state: FeatureActionsState,
  action: FeatureActionsAction,
) => {
  switch (action.type) {
    case 'DELETE_PENDING':
    case 'FETCH_BY_ID_PENDING':
    case 'FETCH_ALL_PENDING':
    case 'FETCH_YOUR_FEATURES_PENDING':
    case 'UPDATE_FEATURE_STATUS_PENDING':
    case 'SUBMIT_PENDING': {
      return { ...state, isLoading: true };
    }

    case 'FETCH_BY_ID_SUCCESS': {
      return { ...state, isLoading: false, viewedFeature: action.payload?.[0] };
    }

    case 'FETCH_YOUR_FEATURES_SUCCESS': {
      const draft: Feature[] = [];
      const published: Feature[] = [];
      if (!action.payload?.length) {
        return {
          ...state,
          isLoading: false,
          yourFeatures: { draft, published },
        };
      }

      [...action.payload]
        .reverse()
        .forEach((f) =>
          f.status === 'draft' ? draft.push(f) : published.push(f),
        );
      return { ...state, isLoading: false, yourFeatures: { draft, published } };
    }

    case 'FETCH_ALL_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        features: action.payload ? [...action.payload].reverse() : [],
      };
    }
    case 'DELETE_SUCCESS': 
    case 'UPDATE_FEATURE_STATUS_SUCCESS': {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      console.log(`unmapped action ${action.type}`);
      return state
  }
};
const FeatureActionsContext = React.createContext({
  state: featuresInitialState,
  getFeatureById: (id: string) => {},
  submitFeature: (
    feature: Omit<FeaturePayload, 'created_by'>,
    id?: string,
  ) => {},
  getFeatures: () => {},
  getYourFeatures: () => {},
  deleteFeature: (id: string) => {},
  updateStatus: (
    id: string,
    status: Exclude<FeatureStatus, 'voting' | 'draft'>,
  ) => {},
});

export const FeatureActionsProvider: React.FC = ({ children }) => {
  const { uid } = useAuth();
  const [state, dispatch] = useReducer(featuresReducer, featuresInitialState);

  const submitFeature = useCallback(
    async (feature: Omit<FeaturePayload, 'created_by'>, id?: string | null) => {
      if (!uid) return;

      if (id) {
        return featureDocument.updateFeature(id, {
          ...feature,
          created_by: uid,
        });
      }

      return featureDocument.createFeature({
        ...feature,
        created_by: uid,
      });
    },
    [uid],
  );

  const getFeatures = useCallback(async () => {
    dispatch({ type: 'FETCH_ALL_PENDING' });
    const response = await featureDocument.fetchFeatures();
    dispatch({ type: 'FETCH_ALL_SUCCESS', payload: response });
  }, []);

  const getFeatureById = useCallback(
    async (id: string) => {
      if (!uid) return;

      dispatch({ type: 'FETCH_BY_ID_PENDING' });

      const response = await featureDocument.fetchFeatureById(id);
      if (response) {
        return dispatch({ type: 'FETCH_BY_ID_SUCCESS', payload: [response] });
      }
    },
    [uid],
  );

  const getYourFeatures = useCallback(async () => {
    if (!uid) return;

    dispatch({ type: 'FETCH_YOUR_FEATURES_PENDING' });
    const response = await featureDocument.fetchYourFeatures(uid);
    dispatch({ type: 'FETCH_YOUR_FEATURES_SUCCESS', payload: response });
  }, [uid]);

  const deleteFeature = useCallback(
    async (id: string) => {
      dispatch({ type: 'DELETE_PENDING' });
      await featureDocument.deleteFeature(id);
      await getYourFeatures();
      dispatch({ type: 'DELETE_SUCCESS' });
    },
    [getYourFeatures],
  );

  const updateStatus = useCallback(
    async (id: string, status: Exclude<FeatureStatus, 'voting' | 'draft'>) => {
      dispatch({ type: 'UPDATE_FEATURE_STATUS_PENDING' });
      await featureDocument.updateFeatureStatus(id, status);
      await getFeatures();
      dispatch({ type: 'UPDATE_FEATURE_STATUS_SUCCESS' });
    },
    [getFeatures],
  );

  return (
    <FeatureActionsContext.Provider
      value={{
        state,
        getFeatureById,
        submitFeature,
        getFeatures,
        getYourFeatures,
        deleteFeature,
        updateStatus,
      }}
    >
      {children}
    </FeatureActionsContext.Provider>
  );
};
export const useFeatureActions = () => useContext(FeatureActionsContext);
