type IconProps = { width?: number; height?: number };

export const GoogleIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
    {...props}
  >
    <defs>
      <path
        id="prefix__a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#prefix__b)" fill="#FBBC05" d="M0 37V11l17 13z" />
    <path
      clipPath="url(#prefix__b)"
      fill="#EA4335"
      d="M0 11l17 13 7-6.1L48 14V0H0z"
    />
    <path
      clipPath="url(#prefix__b)"
      fill="#34A853"
      d="M0 37l30-23 7.9 1L48 0v48H0z"
    />
    <path
      clipPath="url(#prefix__b)"
      fill="#4285F4"
      d="M48 48L17 24l-4-3 35-10z"
    />
  </svg>
);

export const Logo = (props: IconProps) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#prefix__filter0_d_1_10713)">
      <rect x={24} y={24} width={64} height={64} rx={16} fill="#0089FF" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 51h13.6c1.326 0 2.4-.895 2.4-2H40v-3a4 4 0 014-4h24a4 4 0 014 4v17a4 4 0 01-4 4h-5v4.282c0 2.195-2.55 3.481-4.404 2.223L49 67h-5a4 4 0 01-4-4v-3h9.75c1.243 0 2.25-.895 2.25-2H40v-7z"
      fill="#fff"
    />
    <defs>
      <filter
        id="prefix__filter0_d_1_10713"
        x={0}
        y={0}
        width={112}
        height={112}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={12} />
        <feColorMatrix values="0 0 0 0 0.141176 0 0 0 0 0.215686 0 0 0 0 0.305882 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_10713" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1_10713"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const SlackIcon = (props: IconProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-label="Slack"
    viewBox="0 0 512 512"
    {...props}
  >
    <rect width={512} height={512} rx="15%" fill="#fff" />
    <g fill="#e01e5a">
      <path
        id="prefix__a"
        d="M149 305a39 39 0 01-78 0c0-22 17-39 39-39h39zm19 0a39 39 0 0178 0v97a39 39 0 01-78 0z"
      />
    </g>
    <use
      xlinkHref="#prefix__a"
      fill="#36c5f0"
      transform="rotate(90 256 256)"
    />
    <use
      xlinkHref="#prefix__a"
      fill="#2eb67d"
      transform="rotate(180 256 256)"
    />
    <use
      xlinkHref="#prefix__a"
      fill="#ecb22e"
      transform="rotate(270 256 256)"
    />
  </svg>
  );
};
