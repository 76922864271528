import { Badge } from "@mantine/core";
import { FeatureStatus } from "../firebase/features";

const mapStatusToNameColor = {
  draft: ['Draft', 'gray'],
  voting: ['Voting', 'teal'],
  voting_closed: ['Voting closed', 'red'],
  in_queue: ['In queue', 'lime'],
  in_progress: ['in progress', 'orange'],
  done: ['Done', 'blue'],
};

export const FeatureBadge: React.FC<{ status: FeatureStatus }> = ({ status }) => {
  const [statusTitle, statusColor] = mapStatusToNameColor[status];
  return (
    <Badge style={{ marginLeft: 8 }} variant="dot" color={statusColor}>
      {statusTitle}
    </Badge>
  );
};