import { getApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { routePaths } from '../AppRoutes';

type User = {
  uid: string | null;
  email: string | null;
  name: string | null;
  avatar: string | null;
  token: string | null;
};

type AuthShape = {
  uid: string | null;
  name: string | null;
  email: string | null;
  avatar: string | null;
  isAdmin: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  setCurrentUser: Function;
};

export const adminEmails = [
  'tim.mulqueen@messagebird.com',
  'david.oberholzer@messagebird.com',
  'jen.obyrne@messagebird.com',
  'abdelrahman.aboubkar@messagebird.com',
];

const initialState = {
  name: null,
  uid: null,
  email: null,
  avatar: null,
  isAuthenticated: false,
  isAdmin: false,
  isLoading: false,
  setCurrentUser: () => {},
};

const initialUser = {
  uid: null,
  name: null,
  email: null,
  avatar: null,
  token: null,
};

const AuthContext = React.createContext<AuthShape>(initialState);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User>(initialUser);
  const [isLoading, setIsLoading] = useState(false);
  const hasUserRef = useRef<boolean>(false)
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (currentUser.email) return

    setIsLoading(true);

    const auth = getAuth(getApp());
    const unsubscribe = auth.onAuthStateChanged(async (userInFirebase) => {
      try {
        if (!userInFirebase?.email) return;

        setCurrentUser({
          uid: btoa(userInFirebase.email.toLowerCase()),
          token: await userInFirebase.getIdToken(),
          email: userInFirebase.email,
          avatar: userInFirebase.photoURL,
          name: userInFirebase.displayName,
        });

        hasUserRef.current = true;

        if (pathname === routePaths.login) {
          return navigate(routePaths.index);
        }
      } catch (error) {
        navigate(routePaths.login);
      } finally {
        setIsLoading(false);
        if (!currentUser.email && !hasUserRef.current) {
          navigate(routePaths.login);
        }
        unsubscribe?.();
      }
    });
  }, [currentUser.email, pathname, navigate]);

  return (
    <AuthContext.Provider
      value={{
        ...initialState,
        ...currentUser,
        isLoading,
        setCurrentUser,
        isAuthenticated: !!currentUser.email,
        isAdmin: !!currentUser.email && adminEmails.includes(currentUser.email.toLowerCase()),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthShape => useContext(AuthContext);
