import { getApp } from 'firebase/app';
import {
  initializeFirestore,
  collection,
  doc,
  serverTimestamp,
  runTransaction,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { adminEmails } from '../hooks/useAuth';

export type User = {
  id: string;
  name: string;
  email: string;
  avatar: string;
  created_at: Date;
};

type CreateUserPayload = {
  id: string;
  name: string;
  email: string;
  avatar: string;
};

export const createUser = async ({
  id,
  name,
  avatar,
  email,
}: CreateUserPayload) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });

    const docRef = doc(collection(database, `users`), `${id}`);

    // Transaction used to avoid overwriting an existing user document if it already exists.
    // Originates from bug OMCW-854
    await runTransaction(database, (transaction) => {
      return transaction.get(docRef).then((doc) => {
        if (doc.exists()) {
          // Ignore existing user document creates.
          return;
        }

        transaction.set(docRef, {
          id: id,
          name,
          email,
          created_at: serverTimestamp(),
          avatar,
          submitted_features_count: 0,
          votes_count: 0,
          is_admin: adminEmails.includes(email),
        });
      });
    });
  } catch (e) {}
};

export const getUsersInfo = async (ids: string[]) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const users: User[] = [];
    const q = query(
      collection(database, `users`),
      where('id', 'in', ids),
    );
    const docs = await getDocs(q);

    docs.forEach((user) => {
      const userData = user.data();
      users.push({
        ...userData,
        id: user.id,
        created_at: userData.created_at?.toDate(),
      } as User);
    });

    return users;
  } catch (e) {
    console.error(e);
  }
};
