import React from 'react';
import { Button, Loader, Text } from '@mantine/core';
import { Feature } from '../../firebase/features';
import { useAuth } from '../../hooks/useAuth';
import { useVoting } from '../../hooks/useVoting';

export const VoteButton: React.FC<{
  feature: Feature;
}> = ({ feature }) => {
  const { uid } = useAuth();
  const isVoted = !!(uid && feature.votes?.includes(uid));
  const votesCount = feature.votes?.length || 0;
  const { vote, isLoading } = useVoting();
  const onVoteClick = async () => {
    await vote(feature.id);
  };

  return (
    <>
      <div
        style={{
          position: 'absolute',
          right: '15px',
          top: '15px',
          padding: 4,
          border: '1px solid #e9ecef',
          borderRadius: '5px',
        }}
      >
        <Button
          variant="light"
          disabled={feature.status !== 'voting'}
          color={isVoted ? 'green' : 'blue'}
          radius="xl"
          loaderProps={{ variant: 'dots' }}
          onClick={onVoteClick}
          style={{ minWidth: '80px' }}
        >
          {isLoading ? <Loader color={isVoted ? 'green' : 'blue'} size="sm" variant="dots" /> : isVoted ? 'Voted' : 'Vote'}
        </Button>
        <div style={{ paddingTop: 4 }}>
          <Text align="center" size="sm" weight="bold" color="blue">
            {votesCount} votes
          </Text>
        </div>
      </div>
    </>
  );
};
