import React, { useEffect, useMemo } from 'react';
import {
  Center,
  Group,
  Highlight,
  Menu,
  Space,
  Table,
  Text,
  Title,
} from '@mantine/core';
import {
  RocketIcon,
  StopwatchIcon,
  StackIcon,
  SymbolIcon,
} from '@modulz/radix-icons';
import { useFeatureActions } from '../hooks/useFeatureActions';
import { Loading } from '../components/Loading';
import { Feature } from '../firebase/features';

type InProgressSectionProps = {
  icon: React.ReactNode;
  title: string;
  features: Feature[];
};

const FeatureMenu = () => {
  return (
    <Menu placement="center">
      <Menu.Item icon={<SymbolIcon />}>Show Details</Menu.Item>
    </Menu>
  );
};

const FeatureTable: React.FC<{ features: Feature[] }> = ({ features }) => {
  if (!features.length) {
    return (
      <Center>
        <Text>No feature here yet!</Text>
      </Center>
    );
  }
  const rows = features.map((item) => (
    <tr key={item.id}>
      <td>{item.title}</td>
      <td>{atob(item.created_by)}</td>
      <td>{item.created_at?.toLocaleDateString()}</td>
      <td>
        <FeatureMenu />
      </td>
    </tr>
  ));

  return (
    <Table striped highlightOnHover>
      <thead>
        <tr>
          <th>Feature</th>
          <th>Suggested by</th>
          <th>Suggested at</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

const InProgressSection: React.FC<InProgressSectionProps> = ({
  icon,
  title,
  features,
}) => {
  return (
    <>
      <Text
        style={{ display: 'flex', alignItems: 'center' }}
        weight="bold"
        color="blue"
      >
        {icon}
        <span style={{ paddingLeft: 4 }}>{title}</span>
      </Text>
      <FeatureTable features={features} />
    </>
  );
};

export const InProgress = () => {
  const { state, getFeatures } = useFeatureActions();
  const { inProgress, done, inQueue } = useMemo(() => {
    const inProgress: Feature[] = [];
    const done: Feature[] = [];
    const inQueue: Feature[] = [];

    state.features.forEach((feature) => {
      if (feature.status === 'done') done.push(feature);
      if (feature.status === 'in_queue') inQueue.push(feature);
      if (feature.status === 'in_progress') inProgress.push(feature);
    });

    return { inProgress, done, inQueue };
  }, [state.features]);

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  if (state.isLoading && !state.features.length) {
    return <Loading />;
  }

  return (
    <>
      <Group spacing="xl">
        <Title>What we have in progress</Title>
        <Text>
          <Highlight highlightColor="blue" highlight="Omnichannel Widget">
            In progress status means we added the feature to our road map queue
            and it will be picked up in the upcoming sprints. We also work on
            technical tasks that don't have a visible impact to our customers,
            but they have to be done to make the Omnichannel Widget product
            scalable and highly available. If you're interested to know more,
            please check out our Jira board.
          </Highlight>
        </Text>
      </Group>
      <Space h="xl" />
      <Space h="xl" />
      <InProgressSection
        icon={<StopwatchIcon />}
        features={inProgress}
        title="Current sprint"
      />
      <Space h="xl" />
      <Space h="xl" />
      <InProgressSection
        icon={<StackIcon />}
        features={inQueue}
        title="Up next"
      />
      <Space h="xl" />
      <Space h="xl" />
      <InProgressSection
        icon={<RocketIcon />}
        features={done}
        title="Released"
      />
    </>
  );
};
