import { useCallback, useState } from 'react';
import { updateFeatureVotes } from '../firebase/features';
import { useAuth } from './useAuth';
import { useFeatureActions } from './useFeatureActions';

export const useVoting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { uid } = useAuth();
  const { getFeatures, getYourFeatures } = useFeatureActions();
  const toggleIsLoading = useCallback(() => {
    setIsLoading((prev) => !prev);
  }, []);

  const vote = useCallback(
    async (id: string) => {
      if (!uid) return;
      toggleIsLoading();
      await updateFeatureVotes(id, uid);
      await getFeatures();
      await getYourFeatures();
      toggleIsLoading();
    },
    [uid, toggleIsLoading, getFeatures, getYourFeatures],
  );

  return {
    isLoading,
    vote,
  };
};
