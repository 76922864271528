import { Modal, useMantineTheme, Group, Button } from '@mantine/core';
import React from 'react';

export const AreYouSure: React.FC<{
  opened: boolean;
  onConfirm: () => void;
  toggleModal: () => void;
  isLoading?: boolean;
  title?: React.ReactNode | null;
}> = ({ opened, title, onConfirm, toggleModal, isLoading }) => {
  const theme = useMantineTheme();

  return (
    <Modal
      opened={opened}
      onClose={toggleModal}
      title={title || 'Are you sure?'}
      overlayColor={
        theme.colorScheme === 'dark'
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.95}
    >
      <Group position="right" spacing="md">
        <Button disabled={isLoading} variant="white" onClick={toggleModal}>
          Cancel
        </Button>
        <Button loading={isLoading} onClick={onConfirm} color="red">
          Yes, sure
        </Button>
      </Group>
    </Modal>
  );
};
