import { Text, Col, Grid, Center } from '@mantine/core';
import { CrumpledPaperIcon } from '@modulz/radix-icons';
import { Feature } from '../firebase/features';
import { FeatureCard } from './FeatureCard';
import { Loading } from './Loading';

export const FeaturesList: React.FC<{
  emptyListMessage?: string;
  features: Feature[];
  isLoading: boolean;
}> = ({ features, isLoading, emptyListMessage }) => {
  if (isLoading && !features?.length) {
    return <Loading />;
  }

  if (features.length === 0) {
    return (
      <Center>
        <CrumpledPaperIcon />
        <Text>{emptyListMessage || 'No features!'}</Text>
      </Center>
    );
  }

  return (
    <Grid>
      {features?.map((item) => {
        return (
          <Col span={12} key={item.id}>
            <FeatureCard feature={item} />
          </Col>
        );
      })}
    </Grid>
  );
};
