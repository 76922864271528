import { Routes, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';

import { SubmitFeature } from './pages/SubmitFeature';
import { Home } from './pages/Home';
import { InProgress } from './pages/InProgress';
import { Login } from './pages/Login';
import { ManageFeatures } from './pages/admin/ManageFeatures';
import { Contributors } from './pages/Contributors';
import { Tags } from './pages/admin/Tags';
import { useAuth } from './hooks/useAuth';
import { NotFound } from './pages/NotFound';
import { YourFeatures } from './pages/YourFeatures';
import { EditFeature } from './pages/EditFeature';

export const routePaths = {
  index: '/',
  login: '/login',
  submit: '/submit',
  edit: (id?: string) => `/submit/${id || ':id'}`,
  inProgress: '/in-progress',
  yourFeatures: '/your-features',
  adminManageFeatures: '/admin/manage-features',
  contributors: '/contributors',
  adminTags: '/admin/tags',
};

export const AppRoutes = () => {
  const { pathname } = useLocation();
  const { isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  return (
    <>
      {pathname !== '/login' && <Navbar />}

      <Routes>
        <Route path={routePaths.submit} element={<SubmitFeature />} />
        <Route path={routePaths.edit()} element={<EditFeature />} />
        <Route path={routePaths.inProgress} element={<InProgress />} />
        <Route path={routePaths.yourFeatures} element={<YourFeatures />} />
        <Route path={routePaths.login} element={<Login />} />
        <Route index element={<Home />} />

        <Route
          path={routePaths.adminManageFeatures}
          element={<ManageFeatures />}
        />
        <Route path={routePaths.contributors} element={<Contributors />} />
        <Route path={routePaths.adminTags} element={<Tags />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
