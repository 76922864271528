import React, { useCallback, useState } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { routePaths } from '../../AppRoutes';
import { Link } from 'react-router-dom';
import { Pencil1Icon, TrashIcon } from '@modulz/radix-icons';
import { Feature } from '../../firebase/features';
import { AreYouSure } from '../AreYouSure';
import { useFeatureActions } from '../../hooks/useFeatureActions';
import { useNotifications } from '@mantine/notifications';

export const DraftActions: React.FC<{
  feature: Feature;
}> = ({ feature }) => {
  const { deleteFeature } = useFeatureActions();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const notification = useNotifications();
  const onDeleteClick = useCallback(async () => {
    setIsDeleting(true)
    await deleteFeature(feature.id);
    notification.showNotification({
      color: 'green',
      title: '👍',
      message: 'Draft feature deleted successfully',
    });
    setIsConfirmationModalOpen(false);
  }, [notification, deleteFeature, feature]);

  return (
    <div>
      <AreYouSure
        isLoading={isDeleting}
        opened={isConfirmationModalOpen}
        title="Are you sure you want to delete this feature?"
        onConfirm={onDeleteClick}
        toggleModal={() => {
          setIsConfirmationModalOpen((prev) => !prev);
        }}
      />
      <Tooltip withArrow transition="fade" label="Update">
        <ActionIcon
          color="blue"
          component={Link}
          to={routePaths.edit(feature.id)}
        >
          <Pencil1Icon />
        </ActionIcon>
      </Tooltip>

      <Tooltip withArrow transition="fade" label="Delete">
        <ActionIcon
          onClick={() => setIsConfirmationModalOpen(true)}
          color="blue"
        >
          <TrashIcon />
        </ActionIcon>
      </Tooltip>
    </div>
  );
};
