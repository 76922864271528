import { Button, Center, Paper } from '@mantine/core';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp, getApp } from 'firebase/app';
import { GoogleIcon } from '../components/Icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { routePaths } from '../AppRoutes';
import { useNotifications } from '@mantine/notifications';
import { useAuth } from '../hooks/useAuth';
import { createUser } from '../firebase/users';

const WelcomeMessage: React.FC<{ name: string }> = ({ name }) => (
  <div>
    <div>Hi {name}!</div>
    <div>Welcome to OMCW roadmap voting</div>
  </div>
);

export const startFirebase = async () => {
  try {
    getApp();
  } catch (_) {
    await initializeApp({
      apiKey: 'AIzaSyCYsZ-Sp3ZmcOsfPuV2snRTDlEeYs6hdTg',
      authDomain: 'voting-temp-project.firebaseapp.com',
      projectId: 'voting-temp-project',
      storageBucket: 'voting-temp-project.appspot.com',
      messagingSenderId: '101046598189',
      appId: '1:101046598189:web:5c509b04dc43dd6e9e56a4',
    });
  }

  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  return { auth, provider };
};

export const Login = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const { isAuthenticated, setCurrentUser } = useAuth();

  const loginWithGoogle = async () => {
    const { auth, provider } = await startFirebase();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        const user = result.user;

        if (!user.email?.endsWith('@messagebird.com')) {
          notifications.showNotification({
            color: 'red',
            title: 'Not authorized',
            message:
              "Looks like you're not part of MessageBird organization 🤥",
          });

          return;
        }
        
        // do authentication actions here
        // check if the user exists in FireStore, if not create one
        // with infos like (email, photoURL, displayName)
        setCurrentUser({
          token,
          email: user.email,
          avatar: user.photoURL,
          name: user.displayName,
        });

        await createUser({
          id: btoa(user.email.toLowerCase()),
          email: user.email,
          avatar: user.photoURL || '',
          name: user.displayName || '',
        })
        
        navigate(routePaths.index);

        notifications.showNotification({
          color: 'green',
          title: '👋',
          message: <WelcomeMessage name={user.displayName || user.email} />,
        });
      })
      .catch((error) => {
        notifications.showNotification({
          color: 'red',
          title: "Couldn't login 😔",
          message: error.message,
        });
      });
  };

  if (isAuthenticated) {
    return <Navigate to={routePaths.index} />;
  }

  return (
    <Center style={{ width: 600, height: 600 }}>
      <Paper padding="xl">
        <Button
          leftIcon={<GoogleIcon width={18} height={18} />}
          variant="light"
          onClick={loginWithGoogle}
        >
          Login with Google
        </Button>
      </Paper>
    </Center>
  );
};
