import { Center, Text } from '@mantine/core';
import { StopwatchIcon } from '@modulz/radix-icons';
import { useOoos } from '../hooks/useOoos';

export const Contributors = () => {
  const { ooos, onOoos } = useOoos();
  return (
    <Center style={{ width: 600, height: 600 }}>
      <StopwatchIcon
        onClick={onOoos}
        style={{ userSelect: 'none', cursor: 'pointer' }}
      />
      <Text
        onClick={onOoos}
        style={{ paddingLeft: 4, userSelect: 'none', cursor: 'pointer' }}
      >
        Coming s{ooos}n!
      </Text>
    </Center>
  );
};
