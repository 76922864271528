import { Card, Group, Space, Tab, Tabs, Text } from '@mantine/core';
import { BookmarkIcon, BackpackIcon, PersonIcon } from '@modulz/radix-icons';
import React, { useEffect } from 'react';
import { useFeatureActions } from '../../../hooks/useFeatureActions';
import { Loading } from '../../../components/Loading';

import { Actions } from './components/Actions';
import { Voters } from './components/Voters';
import { FeatureBadge } from '../../../components/FeatureBadge';

export const ManageFeatures = () => {
  const { state, getFeatures } = useFeatureActions();

  useEffect(() => {
    getFeatures();
  }, [getFeatures]);

  if (state.isLoading && !state.features.length) {
    return <Loading />;
  }

  return (
    <>
      {state.features.map((feature) => (
        <Card
          key={feature.id}
          withBorder
          padding="md"
          style={{ marginBottom: 16 }}
        >
          <div>
            <Group position="apart">
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Text weight={500}>{feature.title}</Text>
                <FeatureBadge status={feature.status} />
              </div>
              <Actions feature={feature} />
            </Group>
          </div>
          <Space h="md" />

          <Tabs>
            <Tab label="Description" icon={<BookmarkIcon />}>
              <div
                style={{ overflowY: 'scroll', height: 100 }}
                dangerouslySetInnerHTML={{ __html: feature.description }}
              />
            </Tab>

            <Tab label="Details" icon={<BackpackIcon />}>
              <div style={{ overflowY: 'scroll', height: 100 }}>
                <Text>Votes count: {feature.votes?.length || 0}</Text>
                <Text>Created by: {atob(feature.created_by)}</Text>
                <Text>
                  Creation date: {feature.created_at.toLocaleDateString()}
                </Text>
              </div>
            </Tab>
            <Tab label="Voted by" icon={<PersonIcon />}>
              <div style={{ overflowY: 'scroll', height: 100 }}>
                <Voters votes={feature.votes} />
              </div>
            </Tab>
          </Tabs>
        </Card>
      ))}
    </>
  );
};
