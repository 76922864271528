import { Menu, Text, Divider } from '@mantine/core';
import { AreYouSure } from '../../../../components/AreYouSure';
import React, { useState } from 'react';
import { Feature } from '../../../../firebase/features';
import { useFeatureActions } from '../../../../hooks/useFeatureActions';

const confirmationModalTitle = {
  voting_closed: (title: string) => (
    <Text>
      Are you sure you want to close the voting for <strong>{title}</strong>?
    </Text>
  ),
  in_progress: (title: string) => (
    <Text>
      Are you sure you want to move <strong>{title}</strong> to in progress?
    </Text>
  ),
  done: (title: string) => (
    <Text>
      Are you sure you want to move <strong>{title}</strong> to done?
    </Text>
  ),
  in_queue: (title: string) => (
    <Text>
      Are you sure you want to move <strong>{title}</strong> to in queue?
    </Text>
  ),
};

export const Actions: React.FC<{ feature: Feature }> = ({ feature }) => {
  const { updateStatus, state } = useFeatureActions();
  const [shouldShowConfirmModal, setShouldShowConfirmModal] = useState<
    'voting_closed' | 'in_progress' | 'done' | 'in_queue' | null
  >(null);

  const onConfirmClick = async () => {
    if (!shouldShowConfirmModal) return;
    await updateStatus(feature.id, shouldShowConfirmModal);

    setShouldShowConfirmModal(null);
  };

  return (
    <>
      <div>
        <Menu placement="end">
          <Menu.Item
            onClick={() => {
              setShouldShowConfirmModal('voting_closed');
            }}
          >
            Assign tags
          </Menu.Item>
          <Divider />
          <Menu.Label>Status</Menu.Label>
          <Menu.Item
            onClick={() => {
              setShouldShowConfirmModal('voting_closed');
            }}
          >
            Close voting
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setShouldShowConfirmModal('in_queue');
            }}
          >
            Move to in queue
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setShouldShowConfirmModal('in_progress');
            }}
          >
            Move to In progress
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setShouldShowConfirmModal('done');
            }}
          >
            Move to Done
          </Menu.Item>
        </Menu>
      </div>
      <AreYouSure
        isLoading={state.isLoading}
        opened={!!shouldShowConfirmModal}
        title={
          shouldShowConfirmModal &&
          confirmationModalTitle[shouldShowConfirmModal](feature.title)
        }
        onConfirm={onConfirmClick}
        toggleModal={() => {
          setShouldShowConfirmModal(null);
        }}
      />
    </>
  );
};
