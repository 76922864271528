import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FeatureForm } from '../components/FeatureForm';
import { Loading } from '../components/Loading';
import { useFeatureActions } from '../hooks/useFeatureActions';
import { omit } from '../utils/omit';
import { keysToCamel } from '../utils/toCamel';

export const EditFeature = () => {
  const { id: featureId } = useParams();

  const { getFeatureById, state } = useFeatureActions();

  useEffect(() => {
    if (!featureId) return;

    getFeatureById(featureId);
  }, [getFeatureById, featureId]);

  if (state.isLoading) {
    return <Loading />;
  }

  if (!state.viewedFeature) {
    return null;
  }

  const initialValues = omit(state.viewedFeature, [
    'created_at',
    'updated_at',
    'created_by',
    'id',
  ]);

  return (
    <FeatureForm initialValues={keysToCamel(initialValues)} id={featureId} />
  );
};
