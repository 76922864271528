import { getApp } from 'firebase/app';
import {
  initializeFirestore,
  query,
  getDocs,
  collection,
  orderBy,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

export type TagPayload = { name: string; color: string };
export type Tag = TagPayload & {
  created_at: Date;
};

export const fetchTags = async () => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const tags: Tag[] = [];
    const q = query(collection(database, `tags`), orderBy('created_at'));
    const docs = await getDocs(q);

    docs.forEach((feature) => {
      const tagData = feature.data();
      tags.push({
        ...tagData,
        created_at: tagData.created_at?.toDate(),
      } as Tag);
    });

    return tags;
  } catch (e) {
    console.error(e)
  }
};

export const createTag = async ({ name, color }: TagPayload) => {
  try {
    const database = initializeFirestore(getApp(), {
      experimentalAutoDetectLongPolling: true,
    });
    const addRef = collection(database, `tags`);
    await addDoc(addRef, {
      name,
      color,
      created_at: serverTimestamp(),
    });
  } catch (e) {
    console.error(e)
  }
};
